import { Box, Flex, Heading, VStack } from '@chakra-ui/react';
import { Header } from '../components/Header';
import { useContext, useEffect, useRef, useState } from 'react';
import { httpRequest } from '../utils';
import { Service } from '../components/Service';
import { AppContext } from '../AppContext';

const CHECK_MS =
  Number.parseFloat(`${process.env.REACT_APP_CHECK_MINUTES}`) * 60 * 1000;
const CHECK_URL = `${process.env.REACT_APP_API_URL}/v1/internal`;

const calcStatus = (data: any): 'ok' | 'warn' | 'panic' => {
  let rv = 0;
  for (const env of Object.keys(data)) {
    for (const group of Object.keys(data[env])) {
      for (const status of Object.values<any>(data[env][group])) {
        if (status.s === 0) {
          rv |= 2;
        }
        if (status.s === -1) {
          rv |= 1;
        }
      }
    }
  }
  return rv === 0 ? 'ok' : rv === 1 ? 'warn' : 'panic';
};

export default function InternalStatus() {
  const [data, setData] = useState<any>({});
  const [checkDate, setCheckDate] = useState<Date>(new Date());
  const [mode, setMode] = useState<ReturnType<typeof calcStatus>>('ok');
  const checkRef = useRef(false);
  const { token, setToken } = useContext(AppContext);

  const check = () => {
    httpRequest(CHECK_URL, {
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((result) => {
      if (result === 401) {
        setToken(null);
      } else if (result) {
        setData(result);
        setMode(calcStatus(result));
        setCheckDate(new Date());
      }
    });
  };

  useEffect(() => {
    if (checkRef.current === true) {
      return;
    }
    checkRef.current = true;
    check();
  }, []);

  useEffect(() => {
    const interval = setInterval(check, CHECK_MS);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box pt={{ base: '13px', md: '8px', xl: '8px' }}>
      <Header checkDate={checkDate} mode={mode} />
      <Flex direction="row" justifyContent="flex-start">
        <Flex direction="column" flexGrow={1}>
          {Object.keys(data).map((env, i) =>
            ['production', 'web-support'].includes(env) ? (
              <Box key={i}>
                <Heading color="gray.400" size="md">
                  {env}
                </Heading>
                <VStack spacing={4} margin={2} align="stretch">
                  {Object.keys(data[env]).map((group) => (
                    <Box key={`${env}${group}`}>
                      <Heading color="gray.300" size="sm" m={2}>
                        {group}
                      </Heading>
                      {Object.entries(data[env][group]).map(
                        ([name, status]: [string, any]) => (
                          <Service
                            key={name}
                            name={name}
                            rt={status.rt}
                            url={status.u}
                            status={status.s}
                          />
                        ),
                      )}
                    </Box>
                  ))}
                </VStack>
              </Box>
            ) : null,
          )}
        </Flex>
        <Flex direction="column" flexGrow={1}>
          {Object.keys(data).map((env, i) =>
            ['staging'].includes(env) ? (
              <Box key={i}>
                <Heading color="gray.400" size="md">
                  {env}
                </Heading>
                <VStack spacing={4} margin={2} align="stretch">
                  {Object.keys(data[env]).map((group) => (
                    <Box key={`${env}${group}`}>
                      <Heading color="gray.300" size="sm" m={2}>
                        {group}
                      </Heading>
                      {Object.entries(data[env][group]).map(
                        ([name, status]: [string, any]) => (
                          <Service
                            key={name}
                            name={name}
                            rt={status.rt}
                            url={status.u}
                            status={status.s}
                          />
                        ),
                      )}
                    </Box>
                  ))}
                </VStack>
              </Box>
            ) : null,
          )}
        </Flex>
        <Flex direction="column" flexGrow={1}>
          {Object.keys(data).map((env, i) =>
            ['sandbox'].includes(env) ? (
              <Box key={i}>
                <Heading color="gray.400" size="md">
                  {env}
                </Heading>
                <VStack spacing={4} margin={2} align="stretch">
                  {Object.keys(data[env]).map((group) => (
                    <Box key={`${env}${group}`}>
                      <Heading color="gray.300" size="sm" m={2}>
                        {group}
                      </Heading>
                      {Object.entries(data[env][group]).map(
                        ([name, status]: [string, any]) => (
                          <Service
                            key={name}
                            name={name}
                            rt={status.rt}
                            url={status.u}
                            status={status.s}
                          />
                        ),
                      )}
                    </Box>
                  ))}
                </VStack>
              </Box>
            ) : null,
          )}
        </Flex>
        <Flex direction="column" flexGrow={1}>
          {Object.keys(data).map((env, i) =>
            ['development'].includes(env) ? (
              <Box key={i}>
                <Heading color="gray.400" size="md">
                  {env}
                </Heading>
                <VStack spacing={4} margin={2} align="stretch">
                  {Object.keys(data[env]).map((group) => (
                    <Box key={`${env}${group}`}>
                      <Heading color="gray.300" size="sm" m={2}>
                        {group}
                      </Heading>
                      {Object.entries(data[env][group]).map(
                        ([name, status]: [string, any]) => (
                          <Service
                            key={name}
                            name={name}
                            rt={status.rt}
                            url={status.u}
                            status={status.s}
                          />
                        ),
                      )}
                    </Box>
                  ))}
                </VStack>
              </Box>
            ) : null,
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
